
import { createVNode, defineComponent, onMounted, reactive, ref } from "vue";
import { EditOutlined, DeleteOutlined, FormOutlined, ExclamationCircleOutlined  } from "@ant-design/icons-vue"
import router from "@/router"
import { getArticle, delArticle } from "@/api"
import { message, Modal } from "ant-design-vue";

export default defineComponent({
  components: {
    EditOutlined,
    DeleteOutlined,
    FormOutlined,
    ExclamationCircleOutlined
  },
  setup() {
    const columns = reactive([
      { title: '文章ID', width: 100, dataIndex: 'id', key: 'id', fixed: 'left' },
      { title: '文章标题', width: 230, dataIndex: 'title', key: 'title', fixed: 'left' },
      { title: '文章简介', width: 320, dataIndex: 'info', key: 'info' },
      { title: '来源', width: 100, dataIndex: 'source', key: 'source' },
      { title: '浏览数量', dataIndex: 'browse_num', key: 'browse_num' },
      { title: '点赞数量', dataIndex: 'zan_num', key: 'zan_num' },
      { title: '文章作者', dataIndex: 'author', key: 'author' },
      { title: '标签', dataIndex: 'label', key: 'label' },
      { title: '创建时间', dataIndex: 'time', key: 'time' },
      {
        title: '操作',
        key: 'operation',
        fixed: 'right',
        width: 200,
        slots: { customRender: 'action' },
      },
    ]);
    const articles = reactive({data: null});
    const keyword = ref(null);
    const loading = ref(false);

    // 获取文章参数
    const articleParams = {
      title: "",
      pagenum: 1,
      pagesize: 8
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 1,
      pagesize: articleParams.pagesize
    })

    // 分页
    const changePage = (page: number) => {
      articleParams.pagenum = page;
      getArticle(articleParams).then((res: any) => {
        articles.data = res.data.data.articles
        window.scrollTo(0, 0);
      })
    }

    const jump = (id: number) => {
      router.push(`/admin/comment?id=${id}`);
    }

    const editJump = (id: number) => {
      router.push(`/admin/article-editor?id=${id}`);
    }

     // 搜索
    const onSearch = (value: string) => {
      articleParams.title = value;
      getArticle(articleParams).then((res: any) => {
        articles.data = res.data.data.articles;
        pagination.value.total = res.data.data.total;
      })
    }
    const defArt = (id: string, index: number) => {
      Modal.confirm({
        title: '是否删除该文章',
        icon: createVNode(ExclamationCircleOutlined),
        content: '该操作不可恢复，请谨慎执行！',
        cancelText: "取消",
        okText: "确定",
        onOk() {
          loading.value = true;
          delArticle({id}).then((res: any) => {
            loading.value = false;
            if (res.code == 200) {
              (articles as any).data.splice(index, 1);
              return message.success("删除成功!");
            }
            message.error("删除失败!");
          })
        }
      });
    }
    onMounted(() => {
      loading.value = true;
      getArticle(articleParams).then((res: any) => {
        articles.data = res.data.data.articles;
        pagination.value.total = res.data.data.total;
        loading.value = false;
      })
    })
    return {
      columns,
      loading,
      articles,
      keyword,
      pagination,
      changePage,
      jump,
      editJump,
      onSearch,
      defArt
    }
  }
})
